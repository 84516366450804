<template lang="pug">
div
  div.privateleasing.row(v-bind:class="justifyClass")
    MonthChoiser(
      :fixedCostsBy="fixedCostsByDistance"
      :showPices="true"
      v-bind:class="marginClass"
    )

    DistanceChoiser(
      :fixedCostsBy="fixedCostsByMonth"
      :showPices="true"
      @setDownPayments="setDownPayments"
    )
  CashPayment(v-if="this.useCashPayments")
</template>

<script>
import CostsMixin from '@/mixins/CostsMixin'
import {mapState} from "vuex";

export default {
  mixins: [ CostsMixin ],
  components: {
    MonthChoiser: () => import('./../costs-parts/month'),
    DistanceChoiser: () => import('./../costs-parts/distance'),
    CashPayment: () => import('../costs-components/cashPayment'),
  },
  methods: {
    setActive(value) {
      this.$emit('click', value)
    }
  },
  computed: {
    ...mapState('reseller', {useCashPayments: state => state.resellerInfo.settings?.useCashPayments }),
    justifyClass() {
      const elementsCount = this.fixedCostsByDistance.length + this.fixedCostsByMonth.length
      return elementsCount > 5 ? 'justify-content-between' : 'justify-content-center'
    },
    marginClass() {
      return this.fixedCostsByDistance.length < 3 ? 'm-r-40' : ''
    }
  }
}
</script>

<style lang="sass">
  .m-r-40
    margin-right: 40px
</style>